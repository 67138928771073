.cover-container > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.cover-container {
    height: 95vh;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.cover-container > h1{
    font-size: 100px;
    padding: 5vh;
}

.cover-container > p {
    margin-top: 8px;
    font-size: 32px;
    padding: 5vh;
    font-weight: bolder;
}