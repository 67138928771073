.project-value-container {
    background-color: #242424;
    padding: 1rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .project-value-heading {
    color: #fff;
    margin-bottom: 24px;
    font-size: 24px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  

  .project-input-areas {
    background-color: #242424;
    color: #fff;
    width: 90%;
  }
  
  .input-form {
    display: flex;
    flex-direction: column;
    /* grid-row: 1; */
  }
  
  .field-container {
    /* display: flex; */
  }
  
 
@media screen and (max-width: 768px) {
  .input-form > div {
    font-size: smaller;
    /* display: flex; */
    /* flex-direction: row; */
    /* flex-grow: 1; */
  }
}