.project-card-container{
    padding: 1rem;
    display: flex;
    width:92%;
    background-color: rgba(44, 44, 44, 0.599);
    margin-top: 2rem;
}
.project-card-container > img{
    border-radius: 1rem;
    object-fit: cover;
    width: 33%;
}


.project-card-title{
    background-color: rgba(255, 255, 255, 0.314);
    margin-bottom: 1rem;
    padding: 1rem;
}

.project-card-entry > img {
    max-width: 100%;
    max-height: 100%;
}

.project-card-entry{
    height: 20rem;
    padding: 1rem;
}


@media screen and (max-width: 768px) {
    .project-card-container{
        align-items: center;
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 1rem;
        width: 100%;
    }
    .project-card-container > img{
        width: 92%;
        height: 5rem;
    }
    
}