:root {
    --primary: #fff;
    --secondary: #242424;
}


.navbar{
    background: linear-gradient(90deg, rgb(28,27,27) 0%, rgb(26,23,23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top:0;
    z-index: 99;
}

.navbar-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
}

.navbar-logo{
    padding-top: 5px;
    width: 2.5rem;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

#fr-logo{
    width: 2.5rem;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-item{
    height: 80px;
}

.nav-links {
    color: var(--primary);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.nav-links:hover{
    border-bottom: 4px solid var(--primary);
    transition: all 0.2s ease-out;
}

.fa-bars{
    color: var(--primary);
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem; 
        width: 100%;
        display: table;
    }

    .nav-links:hover{
        background-color: var(--primary);
        color: var(--secondary);
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top:0;
        left: 0;
        /* transform: translate(25%, 50%); */
    }
    .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--primary);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: var(--primary);
    padding: 14px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: var(--primary);
    color: var(--secondary);
    transition: 250ms;
  }
}