.program-display-background{
    filter: brightness(50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left:0;
    z-index: -1;
}

.program-list {
    color:white;
    width: 100%;
    list-style-type: none;
}


