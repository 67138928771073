.profile-container{
    padding: 0.5rem;
    /* background-color: black; */
    width: 100%;
    overflow-x: hidden;
}

.profile-section-1 {
    margin-bottom: 0.5rem;
    background-color: #ffffff31;
    display: flex;
}

.profile-section-1 > img {
    height: 500px;
    object-fit: contain;
}

.profile-section-1 > video {
    height: 500px;
    object-fit: contain;
}

.profile-section-2 {
    margin-bottom: 0.5rem;
    background-color: #000000b5;
    display: flex;
    flex-direction: row-reverse;
}

.profile-section-2 > img {
    height: 500px;
    object-fit: contain;
}

.profile-section-2 > video {
    height: 500px;
    object-fit: contain;
}

.profile-text-container {
    display:flex;
    flex: 4;
    align-items: center;
    color: #fff;
}

.profile-text-container > h1{
    padding: 2rem 3rem;
    flex: 1;
    
}

.profile-text-container > div{
    flex: 3;
    padding: 2rem 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 768px){
    .profile-section-1{
        flex-direction: column;
    }
    .profile-section-2{
        flex-direction: column;
    }

    
    .profile-text-container > h1{
        padding: 0.5rem 1.5rem;
        flex: 1
    }
    .profile-text-container > div{
        flex: 3;
        padding: 0;
    }
}