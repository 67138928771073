* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family:'Roboto', sans-serif;
}

@media screen and (max-width: 768px) {
    *{
        /* overflow-x: hidden; */
    }

}

