.project-display-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    padding-bottom: 1rem;
    color:white;
}

/* .project-display-background{
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left:0;
    z-index: -1;
} */
