.switch-title-container{
    padding: 8px 20px;
    font-size: 20px;
}


.switch-container > div {
    padding: 8px 20px;
    overflow-wrap: break-word;
    overflow: hidden;
    width: 11rem;
    inline-size: 10rem;
    padding-right: 10px;
    border-radius: 2px;
    outline: none;
    border: none;
    font-size: 18px;
    border: 1px solid #fff;
}

.pos {
    background-color: greenyellow;
    color: black;
}

.neg {
    background-color: red;
    color: black;
}

@media screen and (max-width: 768px) {
    .input-form > div {
      font-size: 14px;
      /* display: flex; */
      /* flex-direction: row; */
      /* flex-grow: 1; */
    }
  }