.contact-card-container {
    height:60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.contact-display-background{
    filter: brightness(30%);
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left:0;
    z-index: -1;
}

.text-box {
    color: white;
    background-color:  rgba(84, 244, 255, 0.548);
    padding: 2vh 8vh;
    border-radius: 2vh;
}

.text-box > h1 {
    padding: 2vh;
    
}

.text-box > h2 {
    padding: 0.5vh;
    
}

